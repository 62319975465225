import commonStyles from 'styles/common.module.css';
import styles from './Content.module.css';
import Particles from 'components/Particles/Particles';

const Content = () => {
  return (
    <div className={`${commonStyles.bigText} ${styles.content}`}>
      Innovative Systems & Experimental Research.
      <Particles />
    </div>
  );
};

export default Content;
