import Logo from 'assets/iser_labs_128_White.png';
import commonStyles from 'styles/common.module.css';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div className={styles.header}>
      <img src={Logo} alt='Iser Labs' className={styles.logo} />
      <span className={commonStyles.bigText}>Iser Labs</span>
    </div>
  );
};

export default Header;
