import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Content from 'components/Content/Content';

const App = () => {
  return (
    <Container>
      <Header />
      <Content />
      <Footer />
    </Container>
  );
};

export default App;
