import { useEffect, useRef } from 'react';

const NUM_PARTICLES = 100;
const WIDTH = 300;
const HEIGHT = 350;

class Particle {
  constructor(width, height) {
    this.x = Math.random() * width;
    this.y = Math.random() * height;
    this.radius = 1;
    this.speed = 0.1;
    this.angle = Math.random() * 360;
    this.color = 'white';
  }
}

const Particles = () => {
  const canvasRef = useRef(null);

  const draw = (ctx, particles) => {
    const width = ctx.canvas.width;
    const height = ctx.canvas.height;

    ctx.fillStyle = 'rgb(42,42,42)';
    ctx.fillRect(0, 0, width, height);

    const updateParticlePosition = (particle, width, height) => {
      const { x, y, speed, angle } = particle;
      particle.x = x + speed * Math.cos((angle * Math.PI) / 180);
      particle.y = y + speed * Math.sin((angle * Math.PI) / 180);
      if (particle.x < 0) {
        particle.x = width;
        particle.angle = Math.random() * 360;
      }
      if (particle.x > width) {
        particle.x = 0;
        particle.angle = Math.random() * 360;
      }
      if (particle.y < 0) {
        particle.y = height;
        particle.angle = Math.random() * 360;
      }
      if (particle.y > height) {
        particle.y = 0;
        particle.angle = Math.random() * 360;
      }
    };

    const drawConnectingLines = (particle, particles) => {
      const distanceToDrawLine = 30;

      particles.forEach((p) => {
        const { x, y, color } = p;
        const xd = x - particle.x;
        const yd = y - particle.y;
        const distance = Math.sqrt(xd ** 2 + yd ** 2);

        if (distance < distanceToDrawLine) {
          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.moveTo(particle.x, particle.y);
          ctx.lineTo(x, y);
          ctx.strokeStyle = color;
          ctx.stroke();
        }
      });
    };

    particles.forEach((particle) => {
      const { x, y, radius, color } = particle;

      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();

      updateParticlePosition(particle, width, height);
      drawConnectingLines(particle, particles);
    });
  };

  useEffect(() => {
    const particles = [];
    for (let i = 0; i < NUM_PARTICLES; i++) {
      particles.push(new Particle(WIDTH, HEIGHT));
    }

    const canvas = canvasRef.current;
    canvas.width = WIDTH;
    canvas.height = HEIGHT;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const render = () => {
      draw(ctx, particles);
      animationFrameId = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} />;
};

export default Particles;
