import LinkedinLogo from 'assets/linkedin48.png';
import commonStyles from 'styles/common.module.css';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={`${commonStyles.mainText} ${styles.footer}`}>
      <a
        href='https://www.linkedin.com/company/iser-labs'
        target='_blank'
        rel='noreferrer'
      >
        <img src={LinkedinLogo} alt='Linkedin' />
      </a>
      <div>2018-present © Iser Labs</div>
    </div>
  );
};

export default Footer;
